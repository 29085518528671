import { useState } from 'react';
import { useSpring, animated, a } from 'react-spring'
import VisibilitySensor from "react-visibility-sensor";

import allMonths from "./../../images/all_months.jpg";
import specificMonth from "./../../images/specific_month.jpg";




export default function TheAlcoholBook() {

    const [isInView, setIsInView] = useState(false);
    const [isInView1, setIsInView1] = useState(false);
    const [isInView2, setIsInView2] = useState(false);

    const onVisibilityChange = isInView => isInView ? setIsInView(isInView) : null;
    const onVisibilityChange1 = isInView1 => isInView1 ? setIsInView1(isInView1) : null;
    const onVisibilityChange2 = isInView2 => isInView2 ? setIsInView2(isInView2) : null;

    const fadeIn = useSpring({
        transform: "translate(0px, -20px)",
        from: { opacity: isInView ? 1 : 1 },
        to: { opacity: isInView ? 1 : 1, transform: isInView ? "translate(0, 0)" : "translate(0, -0px)" },
        delay: isInView ? 200 : 0,
        config: { duration: isInView ? 1000 : 0 }
    })
    const fadeIn1 = useSpring({
        transform: "translate(0px, -20px)",
        from: { opacity: isInView1 ? 0 : 1 },
        to: { opacity: isInView1 ? 1 : 0, transform: isInView1 ? "translate(0, 0)" : "translate(0, -0px)" },
        delay: isInView1 ? 100 : 0,
        config: { duration: isInView1 ? 1000 : 0 }
    })
    const fadeIn2 = useSpring({
        transform: "translate(0px, 0px)",
        from: { opacity: isInView2 ? 0 : 1 },
        to: { opacity: isInView2 ? 1 : 0, transform: isInView2 ? "translate(0, 0)" : "translate(0, 0px)" },
        delay: isInView2 ? 100 : 0,
        config: { duration: isInView2 ? 500 : 0 }
    })

    const [flipped, set] = useState(false)
    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 300, friction: 80 },
    })


    return (

        <div className="row alcohol-book-div  justify-content-center">

            <div className="col-sm-12 text-center">

                <VisibilitySensor onChange={onVisibilityChange}>
                    <animated.div style={fadeIn}>
                        <h1 className="h1Class" style={{ fontSize: "3rem" }}>
                            The Alcohol Book
                        </h1>
                    </animated.div>
                </VisibilitySensor>

            </div>


            <div className="col-sm-12">
                <div className="row justify-content-center">
                    <div className="col-sm-3">
                        <hr className="features-hr" />
                    </div>
                </div>
            </div>


            <div className="row text-center justify-content-center mt-5">

                <div className="col-12 overlay-container">


                    <img src={allMonths} alt="" className="img-fluid overlay-div" />
                    {/* <img src={specificMonth} alt="" className="img-fluid overlay-center" /> */}

                </div>

            </div>


            <div className="row mt-5">
                <div className="col-sm-12 text-center p-5">

                    <VisibilitySensor onChange={onVisibilityChange1} partialVisibility={true}>
                        <animated.div style={fadeIn1}>
                            <h5 >
                                <span style={{ color: "grey" }}>Opening</span>
                                <span style={{ color: "grey" }}> + Incoming</span>
                                <span style={{ color: "grey" }}> - Outgoing</span>
                                <span style={{ color: "grey" }}> - Losses</span>
                                <span style={{ color: "grey" }}> = Closing Litres Of Pure Alcohol</span>
                            </h5>
                        </animated.div>
                    </VisibilitySensor>

                </div>
            </div>

        </div >

    )


}