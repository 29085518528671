import { useState } from 'react';
import { useSpring, animated, Spring } from 'react-spring'
import VisibilitySensor from "react-visibility-sensor";

import temp3 from "./../../images/dashboard.png";



import regulations from "./../../images/why_edistillery_icons/regulations2.svg";
import alcohol_book from "./../../images/why_edistillery_icons/alcohol_book.svg";
import virtual_warehouse from "./../../images/why_edistillery_icons/virtual_warehouse.svg";
import stocktakes from "./../../images/why_edistillery_icons/stocktakes.svg";
import calculations from "./../../images/why_edistillery_icons/calculations.svg";
import free_trial from "./../../images/why_edistillery_icons/free_trial.svg";
import reports from "./../../images/why_edistillery_icons/reports.svg";





export default function WhyEdistillery2() {


    const [isInView, setIsInView] = useState(false);
    const [isInView1, setIsInView1] = useState(false);
    const [isInView2, setIsInView2] = useState(false);
    const [isInView3, setIsInView3] = useState(false);
    const [isInView4, setIsInView4] = useState(false);

    const onVisibilityChange = isInView => isInView ? setIsInView(isInView) : null;
    const onVisibilityChange1 = isInView1 => isInView1 ? setIsInView1(isInView1) : null;
    const onVisibilityChange2 = isInView2 => isInView2 ? setIsInView2(isInView2) : null;
    const onVisibilityChange3 = isInView3 => isInView3 ? setIsInView3(isInView3) : null;
    const onVisibilityChange4 = isInView4 => isInView4 ? setIsInView4(isInView4) : null;


    const fadeIn = useSpring({
        transform: "translate(0px, -20px)",
        from: { opacity: 1 },
        to: { opacity: 1, transform: isInView ? "translate(0, 0)" : "translate(0, 0px)" },
        delay: isInView ? 200 : 0,
        config: { duration: isInView ? 1000 : 0 }
    })

    const fadeIn1 = useSpring({
        transform: "translate(0px, 0px)",
        from: { opacity: isInView1 ? 0 : 1 },
        to: { opacity: isInView1 ? 1 : 0, transform: isInView1 ? "translate(0, 0)" : "translate(0, 0px)" },
        delay: isInView1 ? 0 : 0,
        config: { duration: isInView1 ? 0 : 0 }
    })
    const fadeIn2 = useSpring({
        transform: "translate(0px, 0px)",
        from: { opacity: isInView2 ? 0 : 1 },
        to: { opacity: isInView2 ? 1 : 0, transform: isInView2 ? "translate(0, 0)" : "translate(0, 0px)" },
        delay: isInView2 ? 0 : 0,
        config: { duration: isInView2 ? 0 : 0 }
    })
    const fadeIn3 = useSpring({
        transform: "translate(0px, 0px)",
        from: { opacity: 1 },
        to: { opacity: 1, transform: isInView3 ? "translate(0, 0)" : "translate(0, 0px)" },
        delay: isInView3 ? 0 : 0,
        config: { duration: isInView3 ? 0 : 0 }
    })
    const fadeIn4 = useSpring({
        transform: "translate(0px, 0px)",
        from: { opacity: 1 },
        to: { opacity: 1, transform: isInView4 ? "translate(0, 0)" : "translate(0, 0px)" },
        delay: isInView4 ? 0 : 0,
        config: { duration: isInView4 ? 0 : 0 }
    })

    return (
        <div className="row mt-5 pt-5 text-center  justify-content-center ">

            <div className="col-sm-12 col-md-12 col-lg-12 mb-5 mt-5 text-center align-items-center justify-content-center">


                <VisibilitySensor onChange={onVisibilityChange}>
                    {({ isVisible }) => (
                        <animated.div style={fadeIn}>
                            <h1 className="h1Class" style={{ fontSize: "3rem" }}>Why eDistillery ?</h1>
                        </animated.div>
                    )}
                </VisibilitySensor>

                <div className="row justify-content-center">
                    <div className="col-sm-3">
                        <hr className="features-hr" />
                    </div>
                </div>


                <div className='row why-edistillery-2 justify-content-center'>
                    <div className='col-sm-6 col-md-6 col-lg-4 d-col text-center justify-content-center'>
                        <img src={regulations} alt="" className="img-fluid" />
                        <div>
                            <h6>{window.locale.translate("ATO REGULATIONS")}</h6>
                            <p>{window.locale.translate('eDistillery is built in accordance with the guidelines provided by the ATO. It helps you record all the activities that are subject to ATO\'s regulations.')}</p>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-4 d-col text-center justify-content-center'>
                        <img src={alcohol_book} alt="" className="img-fluid" />
                        <div>
                            <h6>{window.locale.translate("THE ALCOHOL BOOK")}</h6>
                            <p>{window.locale.translate('Track all the ALCOHOL inside your bonded warehouse. It includes incoming, fermented, distilled, aged, filling/bottling, losses, samples, dispatched and etc.')}</p>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-4 d-col text-center justify-content-center'>
                        <img src={virtual_warehouse} alt="" className="img-fluid" />
                        <div>
                            <h6>{window.locale.translate("A VIRTUAL WAREHOUSE")}</h6>
                            <p>{window.locale.translate('Either be stills, storage tanks/barrels or aging barrels - Create yourself a virtual warehouse that can be accessed from anywhere, anytime.')}</p>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-4 d-col text-center justify-content-center'>
                        <img src={stocktakes} alt="" className="img-fluid" />
                        <div>
                            <h6>{window.locale.translate("STOCKTAKES")}</h6>
                            <p>{window.locale.translate('Either manually or set up an automatic snapshot of the entire warehouse anytime.')}</p>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-4 d-col text-center justify-content-center'>
                        <img src={calculations} alt="" className="img-fluid" />
                        <div>
                            <h6>{window.locale.translate("AUTOMATED CALCULATIONS")}</h6>
                            <p>{window.locale.translate('With the help of integrated density and mass volume tables - make use of the automated calculations while mixing, diluting and more...')}</p>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-4 d-col text-center justify-content-center'>
                        <img src={reports} alt="" className="img-fluid" />
                        <div>
                            <h6>{window.locale.translate("REPORTS & STATISTICS")}</h6>
                            <p>{window.locale.translate('Offering extensive reports & statistics, lot tracebility, equipments tracking, productions & dispatches comparisons, forecasts and a lot more.')}</p>
                        </div>
                    </div>
                    {/* <div className='col-sm-6 col-md-6 col-lg-6 d-col text-center justify-content-center'>
                        <img src={free_trial} alt="" className="img-fluid" />
                        <div>
                            <h6>{window.locale.translate("FREE TRIAL")}</h6>
                            <p>{window.locale.translate('Register and join our cummunity now to instantly take advantage of the free tier program for up to three months.')}</p>
                        </div>
                    </div> */}
                </div>



                {/* <ul className="icon-list text-left p-5">
                    <li> <span className="text-muted"> {window.locale.translate("ATO REGULATIONS")} - </span>  {window.locale.translate('eDistillery is built in accordance with the guidelines provided by the ATO. It helps you record all the activities that are subject to ATO\'s regulations')} </li>
                    <li> <span className="text-muted"> {window.locale.translate("THE ALCOHOL BOOK")} - </span>  {window.locale.translate('Track all the ALCOHOL inside your bonded warehouse. It includes incoming, fermented, distilled, aged, filling/bottling, losses, samples and dispatched')} </li>
                    <li> <span className="text-muted"> {window.locale.translate("A VIRTUAL WAREHOUSE")} - </span>  {window.locale.translate('Either be stills, storage tanks/barrels or aging barrels - Create yourself a virtual warehouse that can be accessed from anywhere, anytime.')} </li>
                    <li> <span className="text-muted"> {window.locale.translate("STOCKTAKES")} - </span>  {window.locale.translate('Either manually or set up an automatic snapshot of the entire warehouse anytime')} </li>
                    <li> <span className="text-muted"> {window.locale.translate("AUTOMATED CALCULATIONS")} - </span>  {window.locale.translate('With the help of integrated density and mass volume tables - make use of the automated calculations while mixing and diluting')} </li>
                </ul> */}


            </div>


            <div className="col-sm-12 col-md-12 col-lg-10 mb-5 text-center align-items-center justify-content-center">

                <img src={temp3} alt="" className="img-fluid overlay-div" />

            </div>


        </div>
    )


}