

import logo from "./../images/logo1.png";


function Footer() {
	return (
		<div className="mt-5 mb-3">

			<div
				className="row pl-5 pr-5"
				style={{ backgroundColor: "#", color: "#" }}
			>

				<div className="col-sm-9 col-md-3 col-lg-3">
					<div className="row">
						<div className="col-sm-12 ">
							<img src={logo} alt="" className="logo img-fluid m-0" />
						</div>
						<div className="col-sm-12 ">{/*links*/}</div>
					</div>
				</div>


				<div className="col-sm-12 col-md-9 col-lg-9">
					<div
						className="row"
						style={{
							fontFamily: "Poppins,sans-serif",
							fontWeight: "bold",
							fontSize: "12px",
							lineHeight: "10px",
						}}
					>
						<div className="col-sm-12">
							<h5 className="h1Class" > What is eDistillery?</h5>
							<h6 style={{ color: "rgb(139, 129, 129)", fontSize: "15px" }} >
								Alcohol as an excisable product, needs to be accounted properly, accurately and
								is also liable to be reported intervally to the excisable authorties in each
								country.
								Therefore, eDistillery is designed to take hold of all the alcohol
								moving to and from and inside a warehouse or distillery to maintain
								all records subject to the customs department
								at any time. It also helps in accounting the payable
								excise amount and stocktakes that a distillery needs to submit
								monthly or intervally.

							</h6>
						</div>

					</div>
				</div>

			</div>

			<div className="row mt-5 pl-3 pr-3 footer-copyright-text">
				<div className="col-12 d-flex text-center justify-content-center align-items-center" style={{ fontSize: "13px" }}>
					Copyrights &copy; 2023, eDistillery.
					All rights reserved
					|
					Powered By <a href="https://www.himsglobal.com" className="ml-1" style={{ display: "contents" }}> HIMS GLOBAL Co.  </a>
				</div>
			</div>

		</div>
	);
}

export default Footer;
