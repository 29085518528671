import { useState } from 'react';
import { useSpring, animated, Spring } from 'react-spring'
import VisibilitySensor from "react-visibility-sensor";



function Pricing() {

	const [isInView, setIsInView] = useState(false);
	const [isInView1, setIsInView1] = useState(false);
	const [isInView2, setIsInView2] = useState(false);
	const [isInView3, setIsInView3] = useState(false);
	const [isInView4, setIsInView4] = useState(false);
	const [isInView5, setIsInView5] = useState(false);

	const onVisibilityChange = isInView => isInView ? setIsInView(isInView) : null;
	const onVisibilityChange1 = isInView1 => isInView1 ? setIsInView1(isInView1) : null;
	const onVisibilityChange2 = isInView2 => isInView2 ? setIsInView2(isInView2) : null;
	const onVisibilityChange3 = isInView3 => setIsInView3(isInView3);
	const onVisibilityChange4 = isInView4 => isInView4 ? setIsInView4(isInView4) : null;
	const onVisibilityChange5 = isInView5 => isInView5 ? setIsInView5(isInView5) : null;


	const fadeIn = useSpring({
		transform: "translate(0px, -20px)",
		from: { opacity: isInView ? 1 : 1 },
		to: { opacity: isInView ? 1 : 1, transform: isInView ? "translate(0, 0)" : "translate(0, 0px)" },
		delay: isInView ? 200 : 0,
		config: { duration: isInView ? 1000 : 0 }
	})

	const fadeIn1 = useSpring({
		transform: "translate(0px, 0px)",
		from: { opacity: isInView1 ? 0 : 1 },
		to: { opacity: isInView1 ? 1 : 0, transform: isInView1 ? "translate(0, 0)" : "translate(0, 0px)" },
		delay: isInView1 ? 200 : 0,
		config: { duration: isInView1 ? 1000 : 0 }
	})
	const fadeIn2 = useSpring({
		transform: "translate(0px, 0px)",
		from: { opacity: isInView2 ? 0 : 1 },
		to: { opacity: isInView2 ? 1 : 0, transform: isInView2 ? "translate(0, 0)" : "translate(0, 0px)" },
		delay: isInView2 ? 200 : 0,
		config: { duration: isInView2 ? 1500 : 0 }
	})

	const fadeIn3 = useSpring({
		transform: "translate(0px, -20px)",
		from: { opacity: isInView3 ? 0 : 1 },
		to: { opacity: isInView3 ? 1 : 0, transform: isInView3 ? "translate(0, 0)" : "translate(0, -0px)" },
		delay: isInView3 ? 100 : 0,
		config: { duration: isInView3 ? 3000 : 0 }
	})



	const fadeIn4 = useSpring({
		transform: "translate(-50px, 0px)",
		from: { opacity: isInView4 ? 0 : 1 },
		to: { opacity: isInView4 ? 1 : 0, transform: isInView4 ? "translate(0, 0)" : "translate(-0, 0px)" },
		delay: isInView4 ? 200 : 0,
		config: { duration: isInView4 ? 1000 : 0 }
	})




	const fadeIn5 = useSpring({
		transform: "translate(50px, 0px)",
		from: { opacity: isInView5 ? 0 : 1 },
		to: { opacity: isInView5 ? 1 : 0, transform: isInView5 ? "translate(0, 0)" : "translate(0, 0px)" },
		delay: isInView5 ? 200 : 0,
		config: { duration: isInView5 ? 1000 : 0 }
	})



	return (
		<div className="row pb-5  pl-2 pr-2 text-center justify-content-center align-items-center pricing-div">
			<div className="col-sm-12">

				<VisibilitySensor onChange={onVisibilityChange}>
					{({ isVisible }) => (
						<animated.div style={fadeIn}>
							<h1 className="h1Class" >Pricing </h1>
						</animated.div>
					)}
				</VisibilitySensor>

			</div>

			<div className="col-sm-12">
				<div className="row justify-content-center">
					<div className="col-sm-2">
						<hr className="features-hr" />
					</div>
				</div>
			</div>


			<div className="col-sm-12 mt-5">

				<VisibilitySensor onChange={onVisibilityChange1}>
					{({ isVisible }) => (
						<animated.div style={fadeIn1}>
							<h4 className="h1Class" >At eDistillery, you pay only for what you use</h4>
						</animated.div>
					)}
				</VisibilitySensor>

			</div>
			<div className="col-sm-12">

				<VisibilitySensor onChange={onVisibilityChange2}>
					{({ isVisible }) => (
						<animated.div style={fadeIn2}>
							<h6 className="h4Class" >
								Start your experience now and get all features & modules absolutely
								free for the first 90 days.
							</h6>
						</animated.div>
					)}
				</VisibilitySensor>

			</div>


			<div className="col-sm-12 mt-5 ">

				<VisibilitySensor onChange={onVisibilityChange3} partialVisibility={true}>
					{({ isVisible }) => (
						<animated.div style={fadeIn3}>
							<a href="https://app.edistillery.com.au/register" target="_blank">
								<button class="btn btn-lg mb-3 register-button">
									Start Your Trial Now
								</button>
							</a>
						</animated.div>
					)}
				</VisibilitySensor>
			</div>



			<div className="col-sm-12 p-5 mb-5 ">

				<div className="row d-flex text-center justify-content-center">

					<div className="col-sm-12 col-md-8 col-lg-7 pricing-plans-div text-left p-4">
						<div className='row'>
							<div className='col-sm-12 col-md-10 col-lg-10'>
								<h3 className=''>Standard Pricing</h3>
								<p>
									Virtual Warehouse (Storage),
									Raw Materials,
									Bulk Productions,
									Filling/Bottling,
									Dispatches (Taxable & Taxfree),
									Disposals,
									Dilution,
									Sampling,
									Statistics/Reports & forecasts,
									The Alcohol Book,
									User Management
								</p>
							</div>
							<div className='col-sm-12 col-md-2 col-lg-2 m-auto pt-2 text-center justify-content-center align-items-center'>
								<h6 className='for-quotation'>For Quotation</h6>
								<p>Contact us</p>
							</div>
						</div>
					</div>


					<div className="col-sm-12 col-md-8 col-lg-8 text-center mt-4 p-4">
						<h3>Add-Ons</h3>
					</div>


					<div className="col-sm-12 col-md-8 col-lg-6 pricing-plans-div text-left p-4">
						<div className='row text-center'>
							<div className='col-8 m-auto'>
								<h6 className='text-left ml-lg-5'>Imported Ready Made Beverages</h6>
							</div>
							<div className='col-4 m-auto pt-2'>
								<h6 className='for-quotation'>For Quotation</h6>
								<p>Contact us</p>
							</div>
						</div>
						<hr />
						<div className='row text-center'>
							<div className='col-8 m-auto'>
								<h6 className='text-left ml-lg-5'>Distillations</h6>
							</div>
							<div className='col-4 m-auto pt-2'>
								<h6 className='for-quotation'>For Quotation</h6>
								<p>Contact us</p>
							</div>
						</div>
						<hr />
						<div className='row text-center'>
							<div className='col-8 m-auto'>
								<h6 className='text-left ml-lg-5'>Aging</h6>
							</div>
							<div className='col-4 m-auto pt-2'>
								<h6 className='for-quotation'>For Quotation</h6>
								<p>Contact us</p>
							</div>
						</div>
						<hr />
						<div className='row text-center'>
							<div className='col-8 m-auto'>
								<h6 className='text-left ml-lg-5'>Vendor Bottling/Packaging</h6>
							</div>
							<div className='col-4 m-auto pt-2'>
								<h6 className='for-quotation'>For Quotation</h6>
								<p>Contact us</p>
							</div>
						</div>
					</div>


				</div>

			</div>




		</div>
	);
}

export default Pricing;
