import { useState, useEffect } from "react";
import TreeItem from '@material-ui/lab/TreeItem';
import Sticky from 'react-sticky-el';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import intiazlizeContainer from "./../../../images/Guide/intiazlizeContainer.jpg";
import intiazlizeProduct from "./../../../images/Guide/intiazlizeProducts.jpg";



export default function InitializeInventory() {
    const [currentSubItem, setCurrentSubItem] = useState(null);


    useEffect(() => {

        if (currentSubItem)
            scroller.scrollTo(currentSubItem)
        else
            scroll.scrollToTop()

    });


    return (
        <div>
            <div className="row">
                <div className="col-sm-9 pb-5 mb-5">
                    <Element name="Setup">
                        <h2 style={{ marginBottom: "50px" }}>{window.locale.translate("Inventory Initialization")}</h2>
                        {window.locale.translate("After setting up the products and the containers, the second step to start using the application is to enter your warehouse stock positions into the application.")}
                        <br /><br />
                        {window.locale.translate("To initialize the inventory, it is important to know that eDistillery allows the flexibility to enter the following types of products to be stored in containers:")}
                        <br />
                        1. {window.locale.translate("Distillates")}
                        <br />
                        2. {window.locale.translate("Bulk Products")}
                        <br />
                        3. {window.locale.translate("Raw Materials")}
                        <br />
                        4. {window.locale.translate("Imported Products")}
                        <br />
                        <br /><br />
                        {window.locale.translate("Following types of products depends on your wish whether to store in a container or directly manage inventory:")}
                        <br />
                        1. {window.locale.translate("Raw Materials")}
                        <br />
                        2. {window.locale.translate("Imported Products")}
                        <br /><br />
                        {window.locale.translate("All the inventory can be initialized from ")}
                        <a href="https://app.edistillery.com.au/settings" target="_blank">{window.locale.translate("Settings")}</a>.

                    </Element>
                    <Element name="Lots" className="mt-5">
                        <h5 style={{ marginBottom: "25px" }}> {window.locale.translate("Inventory Lots")} </h5>
                        {window.locale.translate("eDistillery uses a lot wise representation of inventory of your warehouse. Lots denotes the batches in your purchases or productions.")}
                        <br /><br />
                        {window.locale.translate("Lots are optional everywhere in the application. It's up to your choice to specify lots or leave it as blank. If you leave it as blank, the system will automatically store the lot as current date and time.")}
                    </Element>

                    <Element name="" className="mt-5">
                        <h3 style={{ marginBottom: "25px" }}> {window.locale.translate("Containerized Types")} </h3>
                        {window.locale.translate("Each storage container or a cask can hold a single type of product at a time. Also note that each storage container can only hold a single part of that type that is either Heads, Hearts or Tails, a bulk product, a raw material or an imported product.")}
                        <br /><br />
                        {window.locale.translate("Only Trash containers are allowed to contain a mixture of anything. That means a trash container can contain multiple types of products at a single time. When mixed together in a trash container, it cannot be separated. Please be cautious while moving into trash containers. Trash containers should only be used for disposal purposes.")}
                    </Element>
                    <Element name="Distillates" className="mt-5">
                        <h5 style={{ marginBottom: "25px" }}> {window.locale.translate("Initializing Container's Stock")} </h5>
                        <br />
                        {window.locale.translate("To initialize a product in a container, please go to ")} <a href="https://app.edistillery.com.au/settings/containers" target="_blank">{window.locale.translate("settings -> Containers")}</a>.
                        {window.locale.translate("After that, either choose a storage or a trash container and click on the plus icon of that container as shown in the below image.")}
                        <br /><br />
                        <img src={intiazlizeContainer} alt="" style={{ width: "100%" }} />
                        <br /><br />
                        {window.locale.translate("The popup will open where you can enter the quantity in Litres present in that container in your physical warehouse.")}
                        <br /><br />
                        {window.locale.translate("The procedure is same for adding inventory to cask. You can do it in ")} <a href="https://app.edistillery.com.au/settings/casks" target="_blank">{window.locale.translate("settings -> Casks")}</a>.
                        {/* {window.locale.translate("Note that bulk products cannot be aged and hence cannot be stored in casks. ")} */}
                    </Element>
                    <Element name="" className="mt-5">
                        <h3 style={{ marginBottom: "25px" }}> {window.locale.translate("Generally Stored Types")} </h3>
                        {window.locale.translate("Products of types Raw Materials, Imported Products and Finished Products may not stored in containers (depending on your choice). In that case, the inventory for these products can be managed explicitly either by specifying lots or ignoring the lot numbers.")}
                    </Element>
                    <Element name="RawMaterials" className="mt-5">
                        {/* <h5 style={{ marginBottom: "25px" }}> {window.locale.translate("Adding Raw Materials, Imported Products & Finished Products")} </h5> */}
                        {window.locale.translate("Inventory for these products can be initialized in the respective sections by clicking on the plus icon as shown in the image below.")}
                        <br /><br />
                        <img src={intiazlizeProduct} alt="" style={{ width: "100%" }} />
                        <br /><br />
                        {window.locale.translate("For Raw Materials : ")} <a href="https://app.edistillery.com.au/settings/products/rawmaterials" target="_blank">{window.locale.translate("settings -> Products -> Raw Materials")}</a>.
                        <br /><br />
                        {window.locale.translate("For Imported Products : ")} <a href="https://app.edistillery.com.au/settings/products/imported-products" target="_blank">{window.locale.translate("settings -> Products -> Imported Products")}</a>.
                        <br /><br />
                        {window.locale.translate("For Finished Products : ")} <a href="https://app.edistillery.com.au/settings/products/finished-products" target="_blank">{window.locale.translate("settings -> Products -> Finished Products")}</a>.
                        <br /><br />
                        {window.locale.translate("Lots are optional and can be left empty if not known")}.
                    </Element>

                </div>
                <div className="col-sm-3 pr-3">
                    <div className="">
                        <TreeView
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            expanded={["2", "5"]}
                        >
                            <TreeItem nodeId="1" label="Inventory Lots" onClick={() => setCurrentSubItem("Lots")} />
                            <TreeItem nodeId="2" label="Containerized Types" onClick={() => setCurrentSubItem("ContainerizedTypes")} >
                                <TreeItem nodeId="3" label="Distillates & Bulk Products" onClick={() => setCurrentSubItem("Distillates")} />
                            </TreeItem>
                            <TreeItem nodeId="5" label="Generally Stored Types" onClick={() => setCurrentSubItem("NonContainerizedTypes")} >
                                <TreeItem nodeId="6" label="Raw Materials, Imported Products & Finished Products" onClick={() => setCurrentSubItem("RawMaterials")} />
                            </TreeItem>
                        </TreeView>
                    </div>
                </div>
            </div>
        </div>
    )


}