import { useState } from 'react';
import { useSpring, animated, Spring } from 'react-spring'
import VisibilitySensor from "react-visibility-sensor";

import winery from "./../../images/dedicated_for_icons/winery.svg";
import brewery from "./../../images/dedicated_for_icons/brewery.svg";
import bonded_warehouse from "./../../images/dedicated_for_icons/bonded_warehouse.svg";
import distillery from "./../../images/dedicated_for_icons/distillery.svg";
import bulk_spirit from "./../../images/dedicated_for_icons/bulk_spirit.svg";
import institutes from "./../../images/dedicated_for_icons/institutes.svg";





export default function DedicatedFor() {


    const [isInView, setIsInView] = useState(false);
    const [isInView1, setIsInView1] = useState(false);
    const [isInView2, setIsInView2] = useState(false);
    const [isInView3, setIsInView3] = useState(false);
    const [isInView4, setIsInView4] = useState(false);

    const onVisibilityChange = isInView => isInView ? setIsInView(isInView) : null;
    const onVisibilityChange1 = isInView1 => isInView1 ? setIsInView1(isInView1) : null;
    const onVisibilityChange2 = isInView2 => isInView2 ? setIsInView2(isInView2) : null;
    const onVisibilityChange3 = isInView3 => isInView3 ? setIsInView3(isInView3) : null;
    const onVisibilityChange4 = isInView4 => isInView4 ? setIsInView4(isInView4) : null;


    const fadeIn = useSpring({
        transform: "translate(0px, -20px)",
        from: { opacity: 1 },
        to: { opacity: 1, transform: isInView ? "translate(0, 0)" : "translate(0, 0px)" },
        delay: isInView ? 200 : 0,
        config: { duration: isInView ? 1000 : 0 }
    })

    const fadeIn1 = useSpring({
        transform: "translate(0px, 0px)",
        from: { opacity: isInView1 ? 0 : 1 },
        to: { opacity: isInView1 ? 1 : 0, transform: isInView1 ? "translate(0, 0)" : "translate(0, 0px)" },
        delay: isInView1 ? 0 : 0,
        config: { duration: isInView1 ? 0 : 0 }
    })
    const fadeIn2 = useSpring({
        transform: "translate(0px, 0px)",
        from: { opacity: isInView2 ? 0 : 1 },
        to: { opacity: isInView2 ? 1 : 0, transform: isInView2 ? "translate(0, 0)" : "translate(0, 0px)" },
        delay: isInView2 ? 0 : 0,
        config: { duration: isInView2 ? 0 : 0 }
    })
    const fadeIn3 = useSpring({
        transform: "translate(0px, 0px)",
        from: { opacity: 1 },
        to: { opacity: 1, transform: isInView3 ? "translate(0, 0)" : "translate(0, 0px)" },
        delay: isInView3 ? 0 : 0,
        config: { duration: isInView3 ? 0 : 0 }
    })
    const fadeIn4 = useSpring({
        transform: "translate(0px, 0px)",
        from: { opacity: 1 },
        to: { opacity: 1, transform: isInView4 ? "translate(0, 0)" : "translate(0, 0px)" },
        delay: isInView4 ? 0 : 0,
        config: { duration: isInView4 ? 0 : 0 }
    })

    return (
        <div className="row text-center  justify-content-center ">



            {/* 

            <div className="col-sm-12 col-md-12 col-lg-6 mb-5 mt-5 text-center align-items-center justify-content-center">

                <img src={temp3} alt="" className="img-fluid pl-4 pr-4" />

            </div>
 */}

            <div className="col-sm-12 col-md-12 col-lg-12 mt-5 text-center align-items-center justify-content-center">


                <VisibilitySensor onChange={onVisibilityChange}>
                    {({ isVisible }) => (
                        <animated.div style={fadeIn}>
                            <h1 className="h1Class" style={{ fontSize: "3rem" }}>Dedicated For</h1>
                        </animated.div>
                    )}
                </VisibilitySensor>



                <div className="row justify-content-center">
                    <div className="col-sm-3">
                        <hr className="features-hr" />
                    </div>
                </div>
                {/* <ul className="icon-list-dedicated-for text-center p-4">
                    <li> <span className="text-muted"> {window.locale.translate("Wineries")}  </span>  </li>
                    <li> <span className="text-muted"> {window.locale.translate("Breweries")}  </span>  </li>
                    <li> <span className="text-muted"> {window.locale.translate("Bonded Warehouses")}  </span>  </li>
                    <li> <span className="text-muted"> {window.locale.translate("Manufacturers or Distillers of Spirits ")}  </span>  </li>
                    <li> <span className="text-muted"> {window.locale.translate("Bulk Spirit Producers")}  </span>  </li>
                    <li> <span className="text-muted"> {window.locale.translate("Institutes/Companies oblighed to excise regulations")}  </span>  </li>
                </ul> */}


                <div className='row dedicated-for'>
                    {/* <div className='col-sm-6 col-md-6 col-lg-4 d-col text-center justify-content-center'>
                        <img src={winery} alt="" className="img-fluid" />
                        <p>{window.locale.translate("Wineries")}</p>
                    </div> */}
                    <div className='col-sm-6 col-md-6 col-lg-4 d-col text-center justify-content-center'>
                        <img src={bonded_warehouse} alt="" className="img-fluid" />
                        <p>{window.locale.translate("Bonded Warehouses")}</p>
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-4 d-col text-center justify-content-center'>
                        <img src={distillery} alt="" className="" />
                        <p>{window.locale.translate("Distilleries")}</p>
                    </div>
                    {/* <div className='col-sm-6 col-md-6 col-lg-4 d-col text-center justify-content-center'>
                        <img src={brewery} alt="" className="" />
                        <p>{window.locale.translate("Breweries")}</p>
                    </div> */}
                    <div className='col-sm-6 col-md-6 col-lg-4 d-col text-center justify-content-center'>
                        <img src={bulk_spirit} alt="" className="" />
                        <p>{window.locale.translate("Bulk Spirit Producers")}</p>
                    </div>
                    {/* <div className='col-sm-6 col-md-6 col-lg-4 d-col text-center justify-content-center'>
                        <img src={institutes} alt="" className="" />
                        <p>{window.locale.translate("Institutes/Companies oblighed to excise regulations for alcohol")}</p>
                    </div> */}
                </div>


            </div>



        </div>
    )


}